import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Menu, Typography } from 'antd';

import { A, navigate } from 'hookrouter';
import classNames from 'classnames';
import { BulbFilled } from '@ant-design/icons';

import { selectProjectUuid } from '../../views/Project/projectSlice';

import routes from '../../routes.js';

import _axios from 'axios';
const axios = _axios.create({});

const { Text } = Typography;

const SidebarItemsFromRoutes = (props) => {
  const [showDashboards, setShowDashboards] = useState(true);

  const projectUuid = useSelector(selectProjectUuid);

  const { classes, sidebarCollapsed } = props;

  const active = useSelector((state) => state.sidebar.activeKey);

  return (
    <div className={classes.sidebarWrapper}>
      <Menu
        className={classes.list}
        mode="inline"
        inlineCollapsed={sidebarCollapsed}
      >
        {routes.map((route, key) => {
          const targetRoute = '/' + projectUuid + route.path;

          return (
            <Menu.Item
              key={key}
              icon={<route.icon className={classes.itemIcon} />}
              className={
                ' menu-link-li ' +
                `${classNames(classes.item, {
                  [classes.itemActive + ' menu-link-li-active ']:
                    key === active,
                })}`
              }
              onClick={() => navigate(targetRoute)}
            >
              <A href={targetRoute} className={classes.itemLink}>
                <Text className={classes.itemText}>{route.name}</Text>
              </A>
            </Menu.Item>
          );
        })}
      </Menu>

      <div className="updates-box">
        <span>
          <BulbFilled />
        </span>
        <p>Version 2.1.4</p>
        <a href={projectUuid + '/version'}>Learn More</a>
      </div>
    </div>
  );
};

export default SidebarItemsFromRoutes;
