import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { format as formatDate } from 'date-fns';

import { api } from '../../api/api';
import { MAX_STATUS_LOGS } from '../../../constants';

export const loadVersions = createAsyncThunk(
  'status/loadVersions',
  async () => {
    const response = await api.status.versions();
    return response;
  },
);

export const loadLatest = createAsyncThunk('status/loadLatest', async () => {
  const response = await api.status.latest();
  return response;
});

export const loadExecutionLock = createAsyncThunk(
  'status/loadExecutionLock',
  async () => {
    const response = await api.systemlock.executions();
    return response;
  },
);

export const setExecutionLock = createAsyncThunk(
  'status/setExecutionLock',
  async () => {
    const response = await api.systemlock.executions.set();
    return response;
  },
);

export const deleteExecutionLock = createAsyncThunk(
  'status/deleteExecutionLock',
  async () => {
    const response = await api.systemlock.executions.delete();
    return response;
  },
);

export const startSystemLogStream = createAsyncThunk(
  'status/startSystemLogStream',
  async (_, thunkAPI) => {
    const logSource = api.status.logSource();
    logSource.onopen = () => {
      thunkAPI.dispatch(resetSystemLogStream());
    };
    logSource.onerror = () => {
      console.error('Error connecting to log stream');
    };
    logSource.onmessage = (e) => {
      const data = JSON.parse(e.data);
      thunkAPI.dispatch(updateSystemLogStream(data));
    };
  },
);

export const updateSystemLogStream = createAsyncThunk(
  'status/updateSystemLogStream',
  async (log) => {
    const { levelname, timestamp, name, message } = log;
    const timestampFormat = formatDate(
      new Date(timestamp),
      'yyyy/MM/dd HH:mm:ss',
    );
    const id = Math.random() + timestamp;
    let line = '[' + levelname + ']';
    if (levelname === 'INFO') line += '   ';
    line += `     ${timestampFormat}     ${name}   ${message}`;
    return {
      line,
      _id: id,
      level: levelname,
      data: log,
    };
  },
);

export const resetSystemLogStream = createAsyncThunk(
  'status/resetSystemLogStream',
  async () => {
    return [];
  },
);

const statusSlice = createSlice({
  name: 'status',
  initialState: {
    systemLogs: [],
    loaded: false,
    latest_version: 'v2.0.2',
    executionLock: false,
    executionLockLoaded: false,
    versions: {},
  },
  reducers: {
    resetEdit(state, action) {},
  },
  extraReducers: {
    [resetSystemLogStream.fulfilled]: (state, _action) => {
      state.systemLogs = [];
    },
    [updateSystemLogStream.fulfilled]: (state, action) => {
      state.systemLogs = [
        action.payload,
        ...state.systemLogs.slice(0, MAX_STATUS_LOGS - 1),
      ];
    },
    [loadVersions.fulfilled]: (state, action) => {
      state.loaded = true;
      state.versions = action.payload;
    },
    [loadLatest.fulfilled]: (state, action) => {
      state.latest = action.payload;
    },
    [loadExecutionLock.fulfilled]: (state, action) => {
      state.executionLock = true;
      state.executionLockLoaded = true;
    },
    [loadExecutionLock.rejected]: (state, action) => {
      state.executionLock = false;
      state.executionLockLoaded = true;
    },
    [setExecutionLock.fulfilled]: (state, action) => {
      state.executionLock = true;
      state.executionLockLoaded = true;
    },
    [deleteExecutionLock.fulfilled]: (state, action) => {
      state.executionLock = false;
    },
  },
});

export default statusSlice.reducer;
