import React, { useMemo } from 'react';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { Select } from 'antd';

const { Option, OptGroup } = Select;

export default function TimezonePicker(props) {
  const options = useMemo(() => {
    const timezones = moment.tz.names();
    const mappedValues = {};
    const regions = [];

    timezones.forEach((timezone) => {
      const [region] = timezone.split('/');
      if (!(region in mappedValues)) {
        mappedValues[region] = [];
        regions.push(region);
      }
      mappedValues[region].push(timezone);
    });

    return regions.map((region) => {
      const options = mappedValues[region].map((timezone) => {
        return <Option key={timezone}>{timezone}</Option>;
      });
      return (
        <OptGroup key={region} title={region}>
          {options}
        </OptGroup>
      );
    });
  }, []);

  return (
    <Select showSearch defaultValue={momentTimezone.tz.guess()} {...props}>
      {options}
    </Select>
  );
}
