import {
  createSlice,
  createAsyncThunk,
	createDraftSafeSelector,
} from '@reduxjs/toolkit'

import { api } from '../../api/api'
import { selectProjectUuid } from '../Project/projectSlice'

export const loadConnections = createAsyncThunk('connections/loadConnections', async (_, thunkApi) => {
	const projectID = selectProjectUuid(thunkApi.getState());
  const response = await api.connections(projectID);
  return response
})

export const loadConnectionDetail = createAsyncThunk('connections/loadConnectionDetail', async (connectionID, thunkApi) => {
	const projectID = selectProjectUuid(thunkApi.getState());
	const response = await api.connectionDetail(projectID, connectionID)
	return response
})

export const loadConnectionTypes = createAsyncThunk('connections/loadConnectionTypes', async () => {
	const response = await api.connectionTypes()
	return response
})

export const test = createAsyncThunk('connections/test', async () => {
	return 'ok'
})

export const putConnectionUpdate = createAsyncThunk('connections/putConnectionUpdate', async (alias, connection_type_uuid, parameters, projectUuid, connectionUuid) => {
	return 'ok'
})

export const deleteConnection = createAsyncThunk('sharedField/deleteConnection', async (connectionID, thunkApi) => {
	const projectID = selectProjectUuid(thunkApi.getState());
	await api.connectionDetail.delete(projectID, connectionID);
});

const selectSelf = (state) => state.connections;
export const selectConnectionTypes = createDraftSafeSelector(
	selectSelf, 
	(connections) => connections.connectionTypes);
export const selectNewConnectionParameters = createDraftSafeSelector(
	selectSelf, 
	(connections) => connections.newParameters);
export const selectNewConnectionAlias = createDraftSafeSelector(
	selectSelf, 
	(connections) => connections.newAlias);
export const selectConnections = createDraftSafeSelector(
	selectSelf,
	(connections) => connections.connections);
export const selectConnectionsLoading = createDraftSafeSelector(
	selectSelf,
	(connections) => !connections.connectionsLoaded);
export const selectConnectionDetailEdit = createDraftSafeSelector(
	selectSelf,
	({ connectionDetailEdit }) => connectionDetailEdit
);

const connectionsSlice = createSlice({
	name: 'connections',
	initialState: {
		failedToLoad: false, 
		connections: [], 
		connectionsLoaded: false, 
		connectionTypes: [], 
		connectionDetailUuid: '', 
		connectionDetail: '', 
		newConnectionDetail: '', 
		newConnectionTypeUuid: '', 
		newAlias: '', 
		newParameters: {}, 
		setUpNewParameters: false,
		connectionDetailEdit: {
			deleting: false,
			error: null,
		},
	},
	reducers: {
		resetConnections(state, action) {
			state.connections = []
			state.connectionsLoaded = false;
		},
		setConnectionDetail(state, action) {
			state.connectionDetail = action.payload
		},
		setNewParameters(state, action) {
			state.newParameters = action.payload
		},
		changeConnectionDetail(state, action) {
			// this is for the modification of an existing connection
			const label = action.payload.label
			const value = action.payload.value
			
			if (label == 'alias'){
				state.connectionDetail.alias = value
			} else {
				state.connectionDetail.parameters[label] = value
			}
		},
		setNewConnectionDetail(state, action) {
			if(!state.setUpNewParameters) {
				state.setUpNewParameters = true
				action.payload.parameters.forEach(p => {
					let value = state.newParameters[p.name];
					if (value === undefined) {
						value = p.default !== undefined ? p.default : null;
					}
					state.newParameters[p.name] = value;
				})
				// TODO: add other necessary fields
				state.newConnectionTypeUuid = action.payload.uuid
				state.newConnectionDetail = action.payload
			}
		},
		changeNewConnectionDetail(state, action) {
			const label = action.payload.label
			const value = action.payload.value
			if (label == 'alias') {
				state.newAlias = value
			} else {
				state.newParameters[label] = value
			}
		},
		resetConnectionDetail(state) {
			state.connectionDetail = null;
			state.connectionDetailUuid = null;
			state.newParameters = {}
			state.newAlias = ''
		},
	},
	extraReducers: {
		[loadConnections.pending]: (state, action) => {
			state.connections = [];
			state.connectionsLoaded = false;
		},
		[loadConnections.fulfilled]: (state, action) => {
			state.connections = action.payload.results
			state.connectionsLoaded = true;
		},
		[loadConnections.rejected]: (state, action) => {
			state.failedToLoad = true;
			state.connectionsLoaded = false;
		},
		[loadConnectionDetail.fulfilled]: (state, action) => {
		state.connectionDetailUuid = action.payload.uuid
			state.connectionDetail = action.payload
		},
		[loadConnectionTypes.fulfilled]: (state, action) => {
			state.connectionTypes = action.payload
		},
		[putConnectionUpdate.fulfilled]: (state, action) => {
		},
		[test.fulfilled]: (state, action) => {
		},
		[deleteConnection.pending]: (state) => {
			state.connectionDetailEdit.deleting = true;
			state.connectionDetailEdit.error = null;
		},
		[deleteConnection.fulfilled]: (state) => {
			state.connectionDetailEdit.deleting = false;
			state.connectionDetailEdit.error = null;
		},
		[deleteConnection.rejected]: (state, action) => {
			state.connectionDetailEdit.deleting = false;
			state.connectionDetailEdit.error = action.payload;
		},
	},
})

export const { resetConnectionDetail, resetConnections, setConnectionDetail, setNewConnectionDetail, changeConnectionDetail, changeNewConnectionDetail, setNewParameters } = connectionsSlice.actions

export default connectionsSlice.reducer
