import {
    createSlice,
    createAsyncThunk,
} from '@reduxjs/toolkit'
  
import { api } from '../../api/api'
import store from '../../../store.js'
  
export const checkReady = createAsyncThunk('setup/checkReady', async () => {
    const response = await api.ready()
    return response
})

export const submitLogin = createAsyncThunk('setup/login', async () => {
    const setup = store.getState().setup
    const response = await api.setupLogin(setup.username, setup.password)
    return response
})

export const registerAccount = createAsyncThunk('setup/register', async () => {
    const setup = store.getState().setup
    const response = await api.registerAccount(setup.orgName, setup.orgUuid, setup.id_token, setup.access_token, setup.sub, setup.email, setup.username)
    return response
})
  
  const setupSlice = createSlice({
      name: 'setup',
      initialState: {ready: true, readyChecked: false, step: 1, username: '', password: '', orgName: '', orgUuid: '', loginReady: false },
      reducers: {
          setChecked(state, action) {
          },
          setUsername(state, action) {
              state.username = action.payload
          },
          setPassword(state, action) {
              state.password = action.payload
          },
          changeOrgName(state, action) {
              state.orgName = action.payload
          },
          changeOrgUuid(state, action) {
              state.orgUuid = action.payload
          },
          resetReady(state, action) {
              state.readyChecked = false
          },
          setLoginReady(state, action) {
              state.loginReady = true
          }
      },
      extraReducers: {
            [checkReady.pending]: (state, action) => {
                state.readyChecked = true
            },
            [checkReady.fulfilled]: (state, action) => {
                state.ready = action.payload
            },
            [submitLogin.fulfilled]: (state, action) => {
                state.organizations=action.payload.organiations
                state.access_token = action.payload.access_token
                state.email = action.payload.email
                state.id_token = action.payload.id_token
                state.sub = action.payload.sub
                state.username = action.payload.username
                state.step = 2
            },
            [registerAccount.fulfilled]: (state, action) => {
                state.step = 3
            }
      },
  })
  
  export const { setChecked, setUsername, setPassword, changeOrgName, changeOrgUuid, resetReady, setLoginReady } = setupSlice.actions
  
  export default setupSlice.reducer
  