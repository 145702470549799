import {
    createSlice,
    createAsyncThunk,
    createDraftSafeSelector,
  } from '@reduxjs/toolkit'
  
import { api } from '../../api/api'
  
export const loadSharedFields = createAsyncThunk('sharedField/loadSharedFields', async () => {
    const response = await api.sharedFields()
    return response
})

export const deleteSharedField = createAsyncThunk('sharedField/deleteSharedField', async (sharedFieldUUID) => {
    await api.sharedFields.delete(sharedFieldUUID);
});

// selectors
const selectSelf = (state) => state.sharedField;
export const selectSharedFields = createDraftSafeSelector(
    selectSelf,
    ({ sharedFields }) => sharedFields
);
export const selectSharedFieldsLoading = createDraftSafeSelector(
    selectSelf,
    ({ loaded }) => !loaded
);
export const selectSharedFieldEdit = createDraftSafeSelector(
    selectSelf,
    ({ sharedFieldEdit }) => sharedFieldEdit
);
  
const sharedFieldSlice = createSlice({
    name: 'sharedField',
    initialState: {
        loaded: false, 
        sharedFields: [], 
        changeField: {}, 
        changeFieldSet: false, 
        newAlias: '', 
        newParameters: '', 
        newCategories: [], 
        newOptions: {},
        sharedFieldEdit: {
            deleting: false,
            error: null,
        },
    },
    reducers: {
        setChangeField(state, action) {
            state.changeField = action.payload
            state.changeFieldSet = true
        },
        changeAlias(state, action) {
            state.changeField.alias = action.payload
        },
        changeParameters(state, action) {
            state.changeField.parameters = action.payload
        },
        changeCategories(state, action) {
            state.changeField.categories = action.payload
        },
        changeOptions(state, action) {
            state.changeField.options = {
                ...state.changeField.options,
                ...action.payload,
            }
        },
        changeNewAlias(state, action) {
            state.newAlias = action.payload
        },
        changeNewParameters(state, action) {
            state.newParameters = action.payload
        },
        changeNewCategories(state, action) {
            state.newCategories = action.payload
        },
        changeNewOptions(state, action) {
            state.newOptions = {
                ...state.newOptions,
                ...action.payload,
            }
        },
        resetEdit(state, action) {
              state.changeFieldSet = false
              state.changeField = {}
              state.newAlias = ''
              state.newParameters = ''
              state.newCategories = []
              state.newOptions = {}
        },
        resetSharedFields(state) {
            state.sharedFields = [];
            state.loaded = false;
        },
        resetLoaded(state, action) {
            state.loaded = false;
        }
      },
      extraReducers: {
        [loadSharedFields.fulfilled]: (state, action) => {
            state.sharedFields = action.payload.results
            state.loaded = true
        },
        [deleteSharedField.pending]: (state) => {
            state.sharedFieldEdit.deleting = true;
            state.sharedFieldEdit.error = null;
        },
        [deleteSharedField.fulfilled]: (state) => {
            state.sharedFieldEdit.deleting = false;
            state.sharedFieldEdit.error = null;
        },
        [deleteSharedField.rejected]: (state, action) => {
            state.sharedFieldEdit.deleting = false;
            state.sharedFieldEdit.error = action.payload;
        },
      },
  })
  
  export const { changeNewAlias, changeNewParameters, changeNewCategories, changeNewOptions, changeAlias, changeParameters, changeCategories, changeOptions, setChangeField, resetEdit, resetSharedFields, resetLoaded } = sharedFieldSlice.actions
  
  export default sharedFieldSlice.reducer
  