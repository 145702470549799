import {
    createSlice,
    createAsyncThunk,
  } from '@reduxjs/toolkit'
import { compareAsc } from 'date-fns'
  
import { api } from '../../api/api'

export const loadEngines = createAsyncThunk('appManagement/loadEngines', async () => {
    const response = await api.appmanagement.engines()
    return response
})

export const hideEngine = createAsyncThunk('appManagement/hideEngine', async (uuid) => {
    const response = await api.appmanagement.hide(uuid)
    return response
})

export const activateEngine = createAsyncThunk('appManagement/activateEngine', async (uuid) => {
    const response = await api.appmanagement.activate(uuid)
    return response
})

function cmp(a, b) {
    if(a.disabled==b.disabled) {
        let aName = a.models[0].alias.toUpperCase()
        let bName = b.models[0].alias.toUpperCase()
        if (aName < bName) {
            return -1;
          }
          if (aName > bName) {
            return 1;
          }
          return 0;
    }
    return a.disabled - b.disabled;
}

const appManagementSlice = createSlice({
    name: 'appManagement',
    initialState: {loaded: false, executionLock: false, executionLockLoaded: false, engines: []},
    reducers: {
        resetEdit(state, action) {
        },
      },
      extraReducers: {
              [loadEngines.fulfilled]: (state, action) => {
                  state.loaded = true
                  action.payload.results.sort(cmp)
                  state.engines = action.payload.results
              },
              [hideEngine.fulfilled]: (state, action) => {
                  state.engines.forEach(engine => {
                      if (engine.uuid == action.payload) {
                          engine.disabled = true
                      }
                  })
                  state.engines.sort(cmp)
              },
              [activateEngine.fulfilled]: (state, action) => {
                state.engines.forEach(engine => {
                    if (engine.uuid == action.payload) {
                        engine.disabled = false
                    }
                })
                state.engines.sort(cmp)
              }
      },
  })
  
  //export const { loadVersions } = statusSlice.actions
  
  export default appManagementSlice.reducer
  