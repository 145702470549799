import moment from 'moment';

export const DEFAULT_PROJECT_UUID = '00000-00000';

export const MAX_STATUS_LOGS = 100;

// API
export const LOCAL_BASE_URL =
  window.location.protocol + '//127.0.0.1:4101/api/v2';
export const STAGING_BASE_URL =
  window.location.protocol + '//157.230.233.108/api/v2';
export const PRODUCTION_BASE_URL =
  window.location.protocol + '//' + window.location.host + '/api/v2';

export const PRECONFIGURED_DATE_RANGES = Object.freeze({
  'Last Hour': [moment().subtract(1, 'h'), moment()],
  Today: [moment().startOf('day'), moment().endOf('day')],
  'Last 48 Hours': [moment().subtract(48, 'h'), moment().endOf('day')],
  'Last 7 Days': [moment().subtract(7, 'd'), moment().endOf('day')],
  'This Month': [moment().startOf('month'), moment().endOf('month')],
});

export const DEFAULT_DATE_RANGE = PRECONFIGURED_DATE_RANGES['Last 7 Days'];
