import {
  createSlice,
  createAsyncThunk,
  createDraftSafeSelector,
} from '@reduxjs/toolkit';

import { api } from '../../../api/api';
import { selectProjectUuid } from '../../Project/projectSlice';
import FieldFactory from '../../SharedField/FieldFactory';

export const loadTasks = createAsyncThunk(
  'executions/loadTasks',
  async (projectID, thunkApi) => {
    const response = await api.tasks(projectID);
    return response;
  },
);

export const fetchTask = createAsyncThunk(
  'executions/fetchTask',
  async (taskID, thunkApi) => {
    thunkApi.dispatch(resetTaskEdit());
    const projectID = selectProjectUuid(thunkApi.getState());
    const response = await api.tasks.getOne(projectID, taskID);
    return response;
  },
);

export const executeTask = createAsyncThunk(
  'tasks/executeTask',
  async (taskID, thunkApi) => {
    const projectID = selectProjectUuid(thunkApi.getState());
    const response = await api.tasks.execute(projectID, taskID);
    return response;
  },
);

export const deleteTask = createAsyncThunk(
  'sharedField/deleteTask',
  async (taskID, thunkApi) => {
    const projectID = selectProjectUuid(thunkApi.getState());
    await api.tasks.delete(projectID, taskID);
  },
);

export const fetchTaskDependencies = createAsyncThunk(
  'sharedField/fetchTaskDependencies',
  async (taskID, thunkApi) => {
    const projectID = selectProjectUuid(thunkApi.getState());
    return await api.tasks.dependencies(projectID, taskID);
  },
);

// selectors
const selectSelf = (state) => state.tasks;
export const selectTasks = createDraftSafeSelector(
  selectSelf,
  (tasks) => tasks.tasks,
);
export const selectTasksLoading = createDraftSafeSelector(
  selectSelf,
  (tasks) => tasks.loading,
);
export const selectNewTaskParameters = createDraftSafeSelector(
  selectSelf,
  (tasks) => tasks.newTaskParameters,
);
export const selectNewTaskParametersSet = createDraftSafeSelector(
  selectSelf,
  (tasks) => tasks.newTaskParametersSet,
);
export const selectNewTaskManyParameters = createDraftSafeSelector(
  selectSelf,
  (tasks) => tasks.newTaskManyParameters,
);
export const selectTaskCursor = createDraftSafeSelector(
  selectSelf,
  (tasks) => tasks.taskCursor,
);
export const selectTaskEdit = createDraftSafeSelector(
  selectSelf,
  (tasks) => tasks.taskEdit,
);
export const selectTaskEditSet = createDraftSafeSelector(
  selectSelf,
  (tasks) => tasks.taskEditSet,
);
export const selectTaskEditManyParameters = createDraftSafeSelector(
  selectSelf,
  (tasks) => tasks.taskEditManyParameters,
);
export const selectTaskEditValidations = createDraftSafeSelector(
  selectSelf,
  (tasks) => tasks.taskEditValidations,
);
export const selectTaskDelete = createDraftSafeSelector(
  selectSelf,
  (tasks) => tasks.taskDelete,
);
export const selectTaskExecutions = createDraftSafeSelector(
  selectSelf,
  (tasks) => tasks.executions,
);
export const selectTaskDependencies = createDraftSafeSelector(
  selectSelf,
  (tasks) => tasks.taskDependencies,
);
export const selectTaskHelp = createDraftSafeSelector(
  selectSelf,
  (tasks) => tasks.taskHelp,
);

const tasksSlice = createSlice({
  name: 'tasks',
  initialState: {
    tasks: [],
    loading: false,
    newTaskParameters: {},
    newTaskParametersSet: false,
    newTaskManyParameters: {},
    taskCursor: null,
    taskEdit: {},
    taskEditSet: false,
    taskEditManyParameters: {},
    taskEditValidations: {},
    taskDelete: {
      deleting: false,
      error: null,
    },
    executions: {},
    taskDependencies: {
      loading: false,
      error: null,
      data: null,
    },
    taskHelp: {
      open: false,
      // parameter header id
      parameterHeaderId: '',
      actionKey: '',
      // documentation for each action
      // action -> markdown
      documentation: {},
    },
  },

  reducers: {
    setTaskHelpVisible(state, action) {
      state.taskHelp.open = action.payload;
    },
    setTaskHelpParameterHeaderId(state, action) {
      state.taskHelp.parameterHeaderId = action.payload;
      state.taskHelp.open = true;
    },
    setTaskHelpActionKey(state, action) {
      state.taskHelp.actionKey = action.payload;
      state.taskHelp.open = false;
    },
    updateTaskHelp(state, action) {
      state.taskHelp = { ...state.taskHelp, ...action.payload };
    },
    configureTaskHelp(state, action) {
      state.taskHelp.documentation = action.payload;
    },
    changeNewTaskParameterValue(state, action) {
      const { name, value } = action.payload;
      state.newTaskParameters[name] = value;
    },
    configureNewTaskParameters(state, action) {
      action.payload.forEach((param) => {
        param.parameters.forEach((p) => {
          let defaultValue;
          if (p.default !== undefined) {
            defaultValue = p.default;
          } else {
            defaultValue = FieldFactory.getDefaultValue(p.type);
          }

          if (p.many) {
            state.newTaskManyParameters[p.name] = p;
            state.newTaskParameters[p.name] = defaultValue || [];
          } else {
            state.newTaskParameters[p.name] = defaultValue;
          }
        });
      });

      state.newTaskParametersSet = true;
    },

    resetNewTaskParameters(state) {
      state.newTaskParameters = {};
      state.newTaskManyParameters = {};
      state.newTaskParametersSet = false;
    },

    hardSetNewParameters(state, action) {
      state.newTaskParameters = action.payload;
    },

    hardSetEditParameters(state, action) {
      state.taskEdit = action.payload;
    },

    changeTaskEditValue(state, action) {
      const { name, value } = action.payload;
      state.taskEdit[name] = value;
    },
    configureTaskEditParameters(state, action) {
      const { taskCursor } = state;

      action.payload.forEach((param) => {
        param.parameters.forEach((p) => {
          // try reusing a previous value
          let initialValue = state.taskEdit[p.name];
          if (initialValue === undefined) {
            // it doesn't exist, fallback to what was saved
            initialValue = taskCursor.parameters[p.name];
          }
          if (initialValue === undefined) {
            // still doesn't exist, fallback to default initialValue
            initialValue = FieldFactory.getDefaultValue(p.type);
          }
          if (p.many) {
            state.taskEditManyParameters[p.name] = p;
            state.taskEdit[p.name] = initialValue || [];
          } else {
            state.taskEdit[p.name] = initialValue;
          }
        });
      });

      if (!state.taskEditSet) {
        state.taskEditValidations = {};
        state.taskEditSet = true;
      }
    },
    setTaskEditValidation(state, action) {
      const { name, errors } = action.payload;
      state.taskEditValidations[name] = errors;
    },
    resetTaskEdit(state) {
      state.taskEdit = {};
      state.taskEditSet = false;
      state.taskEditManyParameters = {};
      state.taskEditValidations = {};
    },
    resetTasks(state) {
      state.tasks = [];
      state.loading = false;
      state.newTaskParameters = {};
      state.newTaskParametersSet = false;
      state.newTaskManyParameters = {};
      state.taskCursor = null;
      state.taskEdit = {};
      state.taskEditSet = false;
      state.taskEditManyParameters = {};
      state.taskEditValidations = {};
    },
    resetTaskDependencies(state) {
      state.taskDependencies = {
        loading: false,
        error: null,
        data: null,
      };
    },
  },
  extraReducers: {
    [loadTasks.pending]: (state, action) => {
      state.loading = true;
      state.tasks = [];
    },
    [loadTasks.fulfilled]: (state, action) => {
      state.tasks = action.payload;
      state.loading = false;
    },
    [loadTasks.rejected]: (state, action) => {
      // to reset array or not?
      state.tasks = [];
      state.loading = false;
    },
    [fetchTask.fulfilled]: (state, action) => {
      state.taskCursor = action.payload;
      state.taskEdit = {};
    },
    [fetchTask.rejected]: (state, action) => {
      state.taskCursor = null;
    },
    [executeTask.pending]: (state, action) => {
      state.executions[action.meta.arg] = true;
    },
    [executeTask.fulfilled]: (state, action) => {
      const { arg: taskID } = action.meta;
      state.executions[taskID] = false;
    },
    [executeTask.rejected]: (state, action) => {
      state.executions[action.meta.arg] = false;
    },
    [deleteTask.pending]: (state) => {
      state.taskDelete.deleting = true;
      state.taskDelete.error = null;
    },
    [deleteTask.fulfilled]: (state) => {
      state.taskDelete.deleting = false;
      state.taskDelete.error = null;
    },
    [deleteTask.rejected]: (state, action) => {
      state.taskDelete.deleting = false;
      state.taskDelete.error = action.payload;
    },
    [fetchTaskDependencies.pending]: (state) => {
      state.taskDependencies.loading = true;
      state.taskDependencies.error = null;
    },
    [fetchTaskDependencies.fulfilled]: (state, action) => {
      state.taskDependencies.loading = false;
      state.taskDependencies.error = null;
      state.taskDependencies.data = action.payload;
    },
    [fetchTaskDependencies.rejected]: (state, action) => {
      state.taskDependencies.loading = false;
      state.taskDependencies.error = action.error;
    },
  },
});

export const {
  setTaskHelpVisible,
  setTaskHelpParameterHeaderId,
  setTaskHelpActionKey,
  updateTaskHelp,
  configureTaskHelp,
  changeNewTaskParameterValue,
  configureNewTaskParameters,
  resetNewTaskParameters,
  resetTasks,
  changeTaskEditValue,
  configureTaskEditParameters,
  resetTaskEdit,
  setTaskEditValidation,
  resetTaskDependencies,
  hardSetNewParameters,
  hardSetEditParameters,
} = tasksSlice.actions;

export default tasksSlice.reducer;
