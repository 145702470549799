import {
    createSlice,
    createAsyncThunk,
  } from '@reduxjs/toolkit'
  
import { api } from '../../api/api'
    
export const loadHistory = createAsyncThunk('history/loadHistory', async (props) => {
    let response = ''
    if (props.type=='shared-config') {
        response = await api.sharedConfigs.history(props.id)
    }
    if (props.type=='task') {
        response = await api.tasks.history(props.projectID, props.id)
    }
    if (props.type=='connection') {
        response = await api.connections.history(props.projectID, props.id)
    }
    return response
})

const historySlice = createSlice({
    name: 'history',
    initialState: { history: [], historyLoaded: false },
    reducers: {
        resetHistory(state, action) {
            state.historyLoaded = false
            state.history = []
        },
      },
      extraReducers: {
              [loadHistory.fulfilled]: (state, action) => {
                  state.history = action.payload
                  state.historyLoaded = true
              }
      },
  })
  
  export const { resetHistory } = historySlice.actions
  
  export default historySlice.reducer
  