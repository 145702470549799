import { notification } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import {
  successColor,
  dangerColor,
} from '../assets/jss/material-dashboard-react';

const styles = {
  success: {
    maxWidth: '100%',
    width: 'fit-content',
    fontWeight: 'normal',
    background: successColor[2],
    '& *': {
      color: '#fff',
    },
  },
  error: {
    maxWidth: '100%',
    width: 'fit-content',
    fontWeight: 'normal',
    '& *': {
      color: '#fff',
    },
  },
  errorIcon: {
    color: dangerColor[2],
  },
};

/**
 * Export it so components using notification hooks can pass in custom
 * instances of the notification api.
 */
export class Toasts {
  constructor(api) {
    /**
     * @type {import('antd/lib/notification').NotificationInstance}
     * @private
     */
    this.api = api || notification;
  }
  
  error(message, icon) {
    this.api.error({
      message,
      icon: icon || <CloseCircleOutlined style={styles.errorIcon} />,
      style: styles.error,
    });
  }
  
  success(message, icon) {
    this.api.success({
      message,
      icon,
      className: styles.success,
    });
  }
};

const toast = new Toasts();
export default toast;
