import { configureStore } from '@reduxjs/toolkit'

import loginReducer from './UI/views/Login/loginSlice'
import setupReducer from './UI/views/Setup/setupSlice'
import tasksReducer from './UI/views/Tasks/shared/tasksSlice'
import sequencesReducer from './UI/views/Sequences/sequencesSlice'
import engineReducer from './UI/views/Engines/engineSlice'
import connectionsReducer from './UI/views/Connections/connectionsSlice'
import sidebarReducer from './UI/components/Sidebar/sidebarSlice'
import projectReducer from './UI/views/Project/projectSlice'
import sharedConfigReducer from './UI/views/SharedConfig/sharedConfigSlice'
import sharedFieldReducer from './UI/views/SharedField/sharedFieldSlice'
import executionReducer from './UI/views/Executions/executionSlice'
import statusReducer from './UI/views/Status/statusSlice'
import appManagementReducer from './UI/views/AppManagement/appManagementSlice'
import permissionReducer from './UI/views/Permissions/permissionSlice'
import historyReducer from './UI/views/History/historySlice'

const store = configureStore({
  reducer: {
    login: loginReducer,
    setup: setupReducer,
    tasks: tasksReducer,
    executions: executionReducer,
    sequences: sequencesReducer,
    engines: engineReducer,
    connections:connectionsReducer,
    sidebar: sidebarReducer,
    project: projectReducer,
    sharedConfig: sharedConfigReducer,
    sharedField: sharedFieldReducer,
    status: statusReducer,
    appManagement: appManagementReducer,
    permissions: permissionReducer,
    history: historyReducer,
  },
})

export default store