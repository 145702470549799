import {
    createSlice,
    createAsyncThunk,
    createAction,
    createDraftSafeSelector
  } from '@reduxjs/toolkit'
import { format as formatDate } from 'date-fns';
  
import { api } from '../../api/api'
import store from "../../../store.js";
import { selectProjectUuid } from '../Project/projectSlice';
import { MAX_STATUS_LOGS } from '../../../constants';

// actions
export const requestTaskExecute = createAction('executions/requestTaskExecute');
export const loadExecutions = createAsyncThunk('executions/loadExecutions', async (filters, thunkApi) => {
    const projectID = selectProjectUuid(thunkApi.getState());
    let offset = store.getState().executions.offset
    const response = await api.executions(projectID, { 
        created_start: 0, 
        ...filters,
        offset,
    })
    return response
})

export const executeTask = createAsyncThunk('executions/executeTask', async (taskID, thunkApi) => {
    const projectID = selectProjectUuid(thunkApi.getState());
    // if (!currentProject) return;
    thunkApi.dispatch(requestTaskExecute(taskID));
    try {
        const execution = await api.tasks.execute(projectID, taskID);
        return { execution, taskID };
    } catch (e) {
        return thunkApi.rejectWithValue(
            { error: 'An error occured on the server.', taskID },
        );
    }
});

export const killExecution = createAsyncThunk('executions/killExecution', async (executionID, thunkApi) => {
    const projectID = selectProjectUuid(thunkApi.getState());
    try {
        const execution = await api.executions.kill(projectID, executionID);
        return execution;
    } catch (error) {
        return thunkApi.rejectWithValue({
            error: 'An error occured on the server.', 
            executionID
        })
    }
});

export const fetchExecutionDetail = createAsyncThunk('executions/fetchExecutionDetail', async (executionID, thunkApi) => {
    const projectID = selectProjectUuid(thunkApi.getState());
    try {
        const execution = await api.executions.getOne(projectID, executionID);
        return execution;
    } catch (error) {
        return thunkApi.rejectWithValue({
            error: 'An error occured on the server.', 
            executionID
        })
    }
});

export const fetchExecutionStatsPlot = createAsyncThunk('executions/fetchExecutionStatsPlot', async (executionID, thunkApi) => {
    const projectID = selectProjectUuid(thunkApi.getState());
    try {
        const statsPlot = await api.executions.statsPlot(projectID, executionID);
        if (statsPlot.error) {
            throw new Error(statsPlot.errorData.message);
        }
        return statsPlot;
    } catch (error) {
        return thunkApi.rejectWithValue({
            error,
        });
    }
})

export const startExecutionLogStream = createAsyncThunk('status/startExecutionLogStream', async (executionID, thunkAPI) => {
    const projectID = selectProjectUuid(thunkAPI.getState());
    const logSource = api.executions.logSource(projectID, executionID);
    logSource.onopen = () => {
        thunkAPI.dispatch(resetExecutionLogStream(executionID));
    };
    logSource.onerror = (e) => {
        console.error('Error connecting to log stream');
    };
    logSource.onmessage = (e) => {
        const data = JSON.parse(e.data);
        if (data.complete) {
            logSource.close();
        } else {
            thunkAPI.dispatch(updateExecutionLogStream({ executionID, data }));
        }
    };
});

export const updateExecutionLogStream = createAsyncThunk('status/updateExecutionLogStream', async ({ executionID, data: log }) => {
    const { levelname, timestamp, name, message } = log;
    const timestampFormat = formatDate(new Date(timestamp), 'yyyy/MM/dd HH:mm:ss');
    const id = Math.random() + timestamp;
    let line = '[' + levelname + ']';
    if (levelname === 'INFO')
        line += '   ';
    line += `     ${timestampFormat}     ${name}   ${message}`;
    
    return {
        executionID,
        data: {
            line,
            _id: id,
            level: levelname,
            data: log,
        }
    };
});

export const resetExecutionLogStream = createAsyncThunk('status/resetExecutionLogStream', async (executionID) => {
    return executionID;
});

// selectors
const selectSelf = (state) => state.executions;
export const selectExecutions = createDraftSafeSelector(selectSelf, ({executions}) => executions);
export const selectMode = createDraftSafeSelector(
    selectSelf, 
    (executions) => executions.mode
);
export const selectExecutionsLoading = createDraftSafeSelector(
    selectSelf,
    (executions) => executions.loading
);
export const selectExecutionDetail = createDraftSafeSelector(
    selectSelf,
    (executions) => executions.executionDetail
);
export const selectExecutionStatsPlot = createDraftSafeSelector(
    selectSelf,
    (executions) => executions.statsPlot
);
export const selectExecutionLogs = createDraftSafeSelector(
    selectSelf,
    (executions) => executions.executionLogs
);

const executionSlice = createSlice({
    name: 'executions',
    initialState: {
        executions: [],
        moreAvailable: true,
        loading: false, 
        execution: null,
        executionError: null, 
        requestingExecutions: {}, 
        executionDetail: {
            execution: null,
            loading: false,
            error: null,
            // killing
            killing: false,
            killingError: null,
        },
        offset: 0,
        mode: 'all',
        statsPlot: {
            error: null,
            plot: null,
            isFetching: false,
        },
        executionLogs: {},
    },
    reducers: {
        setMode(state, action) {
            state.mode = action.payload
        },
        deleteExecutions(state, action) {
            state.offset = 0
            state.executions = []
        }
    },
    extraReducers: {
        [requestTaskExecute.type]: (state, action) => {
            state.requestingExecutions[action.payload] = true;
        },
        [executeTask.fulfilled]: (state, action) => {
            const { execution, taskID } = action.payload;
            state.execution = execution;
            state.requestingExecutions[taskID] = false;
        },
        [executeTask.rejected]: (state, action) => {
            const { error, taskID } = action.payload;
            state.executionError = error;
            state.requestingExecutions[taskID] = false;
        },
        [killExecution.pending]: (state, _action) => {
            state.executionDetail.killing = true;
            state.executionDetail.killingError = null;
        },
        [killExecution.fulfilled]: (state, action) => {
            const execution = action.payload;
            state.executionDetail.killing = false;
            state.executionDetail.killingError = null;
        },
        [killExecution.rejected]: (state, action) => {
            const { error } = action.payload;
            state.executionDetail.killing = false;
            state.executionDetail.killingError = error;
        },
        [fetchExecutionDetail.pending]: (state, _action) => {
            state.executionDetail = {
                execution: null,
                loading: true,
                error: null,
                killing: false,
                killingError: null,
            };
        },
        [fetchExecutionDetail.fulfilled]: (state, action) => {
            const execution = action.payload;
            state.executionDetail.execution = execution;
            state.executionDetail.loading = false;
            state.executionDetail.error = null;
        },
        [fetchExecutionDetail.rejected]: (state, action) => {
            const { error } = action.payload;
            state.executionDetail.execution = null;
            state.executionDetail.loading = false;
            state.executionDetail.error = error;
        },
        [loadExecutions.pending]: (state, action) => {
            state.loading = true
        },
        [loadExecutions.fulfilled]: (state, action) => {
            state.executions = state.executions.concat(action.payload)
            state.loading = false
            state.offset += 100
            if(action.payload.length < 100) {
                state.moreAvailable = false;
            }
        },
        [fetchExecutionStatsPlot.pending]: (state) => {
            state.statsPlot = {
                error: null,
                plot: null,
                isFetching: true,
            };
        },
        [fetchExecutionStatsPlot.fulfilled]: (state, action) => {
            state.statsPlot = {
                error: null,
                plot: action.payload,
                isFetching: false,
            };
        },
        [fetchExecutionStatsPlot.rejected]: (state, action) => {
            state.statsPlot = {
                error: action.payload.error,
                plot: null,
                isFetching: false,
            };
        },
        [resetExecutionLogStream.fulfilled]: (state, action) => {
            state.executionLogs[action.payload] = [];
        },
        [startExecutionLogStream.fulfilled]: (state, action) => {
            state.executionLogs[action.meta.arg] = [];
        },
        [updateExecutionLogStream.fulfilled]: (state, action) => {
            const { executionID, data } = action.payload;
            state.executionLogs[executionID] = [
                data,
                ...state.executionLogs[executionID].slice(0, MAX_STATUS_LOGS - 1)
            ];
        },
      },
  })
  
  export const { setMode, deleteExecutions } = executionSlice.actions
  
  export default executionSlice.reducer
  