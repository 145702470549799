import {
    createSlice,
    createAsyncThunk,
  } from '@reduxjs/toolkit'
  
import { api } from '../../api/api'
import store from '../../../store.js'

export const loadGroups = createAsyncThunk('permissions/loadGroups', async () => {
    const response = await api.groups()
    return response
})

export const loadUsers = createAsyncThunk('permissions/loadUsers', async () => {
    const response = await api.users()
    return response
})

export const loadProjects = createAsyncThunk('permissions/loadProjects', async () => {
    const response = await api.projects()
    return response
})

export const loadRoles = createAsyncThunk('permissions/loadRoles', async () => {
    const response = await api.roles()
    return response
})

export const saveNewProject = createAsyncThunk('permissions/saveNewProject', async () => {
    let alias = store.getState().permissions.newProjectAlias
    let description = store.getState().permissions.newProjectDescription
    const response = await api.projects.post(alias, description)
    return response
})

export const saveNewRole = createAsyncThunk('permissions/saveNewRole', async () => {
    let role = store.getState().permissions.newRoleName
    let project = store.getState().permissions.newRoleProject
    let actions = store.getState().permissions.newRoleActions
    let resources = store.getState().permissions.newRoleResources
    const response = await api.roles.post(role, project, actions, resources)
    return response
})

export const saveEditRole = createAsyncThunk('permissions/saveEditRole', async () => {
    let role = store.getState().permissions.editRoleName
    let project = store.getState().permissions.newRoleProject
    let actions = store.getState().permissions.newRoleActions
    let resources = store.getState().permissions.newRoleResources
    let role_uuid = store.getState().permissions.editRoleUUID
    const response = await api.roles.put(role, role_uuid, project, actions, resources)
    return response
})

export const saveEditProject = createAsyncThunk('permissions/saveEditProject', async () => {
    let alias = store.getState().permissions.editProjectAlias
    let description = store.getState().permissions.editProjectDescription
    let target = store.getState().permissions.editProjectTarget

    let projects = store.getState().permissions.projects
    let uuid = ''
    projects.forEach(project => {
        if(project.alias==target) {
            uuid = project.uuid
        }
    })
    const response = await api.projects.put(uuid, alias, description)
    return response
})

export const deleteProject = createAsyncThunk('permissions/deleteProject', async (uuid) => {
    const response = await api.projects.delete(uuid)
    return response
})

export const deleteGroup = createAsyncThunk('permissions/deleteGroup', async (uuid) => {
    const response = await api.groups.delete(uuid)
    return response
})

export const deleteRole = createAsyncThunk('permissions/deleteRole', async (props) => {
    const response = await api.roles.delete(props.project_uuid, props.uuid)
    return response
})

export const saveNewGroup = createAsyncThunk('permissions/saveNewGroup', async () => {
    let name = store.getState().permissions.newGroupName
    const response = await api.groups.post(name)
    return response
})

const permissionSlice = createSlice({
    name: 'permissions',
    initialState: {
        usersloaded: false, users: [],
        groupsloaded: false, groups: [], newGroup: false, editGroup: false, _editGroup: {}, editGroupUUID: '', editGroupName: '', editGroupUsers: [], editGroupRoles: [], editGroupUsersSet: false, newGroupUsers: [], newGroupRoles: [],
        rolesloaded: false, roles: [], newRole: false, editRole: false, newRoleName: '', newRoleProject: '00000-00000', newRoleActions: [], newRoleResources: [], editRolesSet: false, editRoleName: '', editRoleUUID: '', editRoleProjectUUID: '', editRoleProject: '', editRoleResources: [], editRoleActions: [],
        projectsloaded: false, projects: [], newProject: false, editProjectTarget: 'nununununnununu', editProjectAlias: '', editProjectDescription: '', newProjectAlias: '', newProjectDescription: '',
        executionLock: false, executionLockLoaded: false, 
        versions: {}
    },
    reducers: {
        // PROJECTS
        addProject(state, action) {
            state.newProject = true
        },
        editProject(state, action) {
            state.editProjectAlias = action.payload.alias
            state.editProjectDescription = action.payload.description
            state.editProjectTarget = action.payload.alias
        },
        addProjectValues(state, action) {
            if (action.payload.field=='alias') {
                state.newProjectAlias = action.payload.value
            }
            if (action.payload.field=='description') {
                state.newProjectDescription = action.payload.value
            }
        },
        editProjectValues(state, action ) {
            if (action.payload.field=='alias') {
                state.editProjectAlias = action.payload.value
            }
            if (action.payload.field=='description') {
                state.editProjectDescription = action.payload.value
            }
        },
        cancelNewProject(state, action) {
            state.newProject = false
            state.newProjectAlias = ''
            state.newProjectDescription = ''
        },
        cancelEditProject(state, action) {
            state.editProjectTarget = 'nununnununununnuunnu'
        },
        // GROUPS
        addGroup(state, action) {
            state.newGroup = true
        },
        editGroup(state, action) {
            state.editGroup = true
            state._editGroup = action.payload.group

            state.editGroupUUID = action.payload.group.uuid
            state.editGroupName = action.payload.group.name
        },
        cancelNewGroup(state, action) {
            state.newGroup = false
            state.editGroup = false
            state.editGroupsSet = false
        },
        addGroupValues(state, action) {
            if (action.payload.field=='group') {
                state.newGroupName = action.payload.value
            }
        },

        // ROLES
        addRole(state, action) {
            state.newRole = true
        },
        editRole(state, action) {
            state.editRole = true
            state.editRoleUUID = action.payload.role_uuid
            state.editRoleName = action.payload.role
            state.editRoleProjectUUID = action.payload.project_uuid
            state.editRoleProject = action.payload.projectname
            state.editRoleResources = action.payload.editRoleResources
            state.editRoleActions = action.payload.editRoleActions
        },
        setEditRolesSet(state, action) {
            state.editRolesSet = true
        },
        addRoleValues(state, action) {
            if (action.payload.field=='role') {
                state.newRoleName = action.payload.value
            }
            if (action.payload.field=='project') {
                state.newRoleProject = action.payload.value
            }
        },
        setNewRoleActions(state, action) {
            state.newRoleActions = action.payload
        },
        setNewRoleResources(state, action) {
            state.newRoleResources = action.payload
        },
        cancelNewRole(state, action) {
            state.newRole = false
            state.editRole = false
            state.editRolesSet = false
        },
        deleteGroups(state, action) {
            state.groupsloaded = false
            state.groups = []
        },
        deleteUsers(state, action) {
            state.usersloaded = false
            state.users = []
        },
        resetEdit(state, action) {
        },
      },
      extraReducers: {
              [loadGroups.fulfilled]: (state, action) => {
                  state.groupsloaded = true
                  state.groups = action.payload.results
              },
              [saveNewGroup.fulfilled]: (state, action) => {
                state.newGroup = false
                state.newGroupName = ''
                state.groupsloaded = false
                state.editGroupsSet = false
              },
              [loadUsers.fulfilled]: (state, action) => {
                  state.usersloaded = true
                  state.users = action.payload.results
              },
              [loadProjects.fulfilled]: (state, action) => {
                  state.projectsloaded = true
                  state.projects = action.payload.results
              },
              [saveNewProject.fulfilled]: (state, action) => {
                state.newProject = false
                state.newProjectAlias = ''
                state.newProjectDescription = ''
                state.projectsloaded = false
              },
              [saveNewRole.fulfilled]: (state, action) => {
                state.newRole = false
                state.newRoleName = ''
                state.newRoleProject = '00000-00000'
                state.rolesloaded = false
                state.editRolesSet = false
              },
              [saveEditRole.fulfilled]: (state, action) => {
                state.editRole = false
                state.editRoleName = ''
                state.editRoleUUID = ''
                state.editRoleProjectUUID = ''
                state.editRoleProject = ''
                state.editRoleResources = []
                state.editRoleActions = []
                state.rolesloaded = false
                state.editRolesSet = false
              },
              [saveEditProject.fulfilled]: (state, action) => {
                state.editProjectAlias = ''
                state.editProjectDescription = ''
                state.projectsloaded = false
              },
              [loadRoles.fulfilled]: (state, action) => {
                  state.rolesloaded = true
                  state.roles = action.payload.results
              },
              [deleteRole.fulfilled]: (state, action) => {
                  state.rolesloaded = false
                  state.roles = []
              },
              [deleteGroup.fulfilled]: (state, action) => {
                state.groupsloaded = false
                state.groups = []
              }
      },
})

export const { 
    resetEdit,
    addGroup, editGroup, cancelNewGroup, deleteGroups, deleteUsers, addGroupValues,
    addRole, editRole, setEditRolesSet, cancelNewRole, addRoleValues, setNewRoleActions, setNewRoleResources,
    addProject, editProject, editProjectValues, addProjectValues, cancelNewProject, cancelEditProject 
} = permissionSlice.actions
  
  export default permissionSlice.reducer
  