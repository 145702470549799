import {
    createSlice,
    createAsyncThunk,
    createDraftSafeSelector,
  } from '@reduxjs/toolkit'
  
import { api } from '../../api/api'
import FieldFactory from '../SharedField/FieldFactory'
  
export const loadSharedConfigTypes = createAsyncThunk('sharedConfig/loadSharedConfigTypes', async () => {
    const response = await api.sharedConfigTypes()
    return response
})

export const loadSharedConfigs = createAsyncThunk('sharedConfig/loadSharedConfigs', async () => {
    const response = await api.sharedConfigs()
    return response
})
    
export const loadSharedConfigHistory = createAsyncThunk('sharedConfig/loadSharedConfigHistory', async (configID) => {
    const response = await api.sharedConfigs.history(configID)
    return response
})

export const deleteSharedConfig = createAsyncThunk('sharedField/deleteSharedConfig', async (sharedConfigUUID) => {
    await api.sharedConfigs.delete(sharedConfigUUID);
});

const selectSelf = (state) => state.sharedConfig;
export const selectSharedConfigs = createDraftSafeSelector(
    selectSelf,
    (sharedConfig) => sharedConfig.sharedConfigs);
export const selectSharedConfigTypes = createDraftSafeSelector(
    selectSelf,
    (sharedConfig) => sharedConfig.sharedConfigTypes);
export const selectSharedConfigsLoading = createDraftSafeSelector(
    selectSelf,
    ({ loaded }) => !loaded
);
export const selectSharedConfigEdit = createDraftSafeSelector(
    selectSelf,
    ({ sharedConfigEdit }) => sharedConfigEdit
);


const sharedConfigSlice = createSlice({
    name: 'sharedConfig',
    initialState: {
        sharedConfigTypes: [], 
        newSharedConfigSet: false, 
        manyParameters: {}, 
        sharedConfigs: [], 
        parametersSet: false, 
        newParameters: {}, 
        loaded: false, 
        sharedFields: [], 
        changeField: {}, 
        changeFieldSet: false, 
        newAlias: '',
        sharedConfigEdit: {
            deleting: false,
            error: null,
        },
    },
    reducers: {
        createNewManyRow(state, action) {
            let manyParameters = state.manyParameters[action.payload];
            let newManyRow = {};
            manyParameters.forEach(parameter => {
                newManyRow[parameter.name] = FieldFactory.getDefaultValue(parameter.type);
            })
            state.newParameters[action.payload].push(newManyRow)
        },
        deleteManyRow(state, action) {
            const { name, index } = action.payload;
            state.newParameters[name].splice(index, 1);
        },
        configureNewSharedConfig(state, action) {
            const { parameters } = action.payload;
            parameters.forEach((category) => {
                if (category.name !== 'alias') {
                    if(category.many || category.type === 'array') {
                        if(!state.newParameters[category.name]) {
                            state.newParameters[category.name] = []
                            state.manyParameters[category.name] = []
                            for (let param of category.parameters) {
                                state.manyParameters[category.name].push(param)
                            }
                        }
                    } else if (category.parameters) {
                        for (let param of category.parameters) {
                            if (param.many || param.type === 'nested') {
                                if(!state.newParameters[param.name]) {
                                    state.newParameters[param.name] = []
                                    state.manyParameters[param.name] = []
                                    for (let p of param.parameters) {
                                        state.manyParameters[param.name].push(p)
                                    }
                                }
                            } else {
                                state.newParameters[param.name] = FieldFactory.getDefaultValue(param.type);
                            }
                        }
                    } else {
                        state.newParameters[category.name] = FieldFactory.getDefaultValue(category.type);
                    }
                }
            });
            state.newSharedConfigSet = true;
        },
        changeParameter(state, action) {
            const section = action.payload.section
            const parameter = action.payload.parameter
            const value = action.payload.value
            const type = action.payload.type
            const many = action.payload.many

            if(many || type === 'array') {
                const index = action.payload.index
                const obj = state.newParameters[section][index];
                obj[parameter] = value;
            } else {
                state.newParameters[parameter] = value
                state.parametersSet = true
            }
        },
        addNewParameter(state, action) {
            if(action.payload.many) {
                if(!state.newParameters[action.payload.name]) {
                    state.newParameters[action.payload.name] = []
                    state.manyParameters[action.payload.name] = []
                    action.payload.parameters.forEach(parameter => {
                        state.manyParameters[action.payload.name].push(parameter)
                    })
                }
            } else {
                state.newParameters[action.payload.name] = null
            }
        },
        hardSetParameters(state, action) {
            state.newParameters = action.payload;
        },
        setChangeField(state, action) {
            state.changeField = action.payload
            state.changeFieldSet = true
        },
        changeAlias(state, action) {
            state.changeField.alias = action.payload
        },
        changeParameters(state, action) {
            state.changeField.parameters = action.payload
        },
        changeNewAlias(state, action) {
            state.newAlias = action.payload
        },
        changeNewParameters(state, action) {
            state.newParameters = action.payload
        },
        resetEdit(state, action) {
            state.changeFieldSet = false
            state.newSharedConfigSet = false
            state.changeField = {}
            state.newAlias = ''
            state.newParameters = {}
            state.parametersSet = false
        },
        resetSharedConfigs(state) {
            state.sharedConfigs = [];
            state.loaded = false;
        }
      },
      extraReducers: {
        [loadSharedConfigTypes.fulfilled]: (state, action) => {
            state.sharedConfigTypes = action.payload.results
        },
        [loadSharedConfigs.pending]: (state, action) => {
            state.sharedConfigs = [];
            state.loaded = false;
        },
        [loadSharedConfigs.fulfilled]: (state, action) => {
            state.sharedConfigs = action.payload.results
            state.loaded = true
        },
        [loadSharedConfigs.rejected]: (state, action) => {
            state.sharedConfigs = [];
            state.loaded = true;
        },
        [loadSharedConfigHistory.fulfilled]: (state, action) => {
            state.sharedConfigHistory = action.payload
            state.historyLoaded = true
        },
        [deleteSharedConfig.pending]: (state) => {
            state.sharedConfigEdit.deleting = true;
            state.sharedConfigEdit.error = null;
        },
        [deleteSharedConfig.fulfilled]: (state) => {
            state.sharedConfigEdit.deleting = false;
            state.sharedConfigEdit.error = null;
        },
        [deleteSharedConfig.rejected]: (state, action) => {
            state.sharedConfigEdit.deleting = false;
            state.sharedConfigEdit.error = action.payload;
        },
      },
  })
  
  export const { addNewParameter, configureNewSharedConfig, changeParameter, changeNewAlias, changeNewParameters, changeAlias, changeParameters, hardSetParameters, setChangeField, createNewManyRow, deleteManyRow, resetEdit, resetSharedConfigs } = sharedConfigSlice.actions
  
  export default sharedConfigSlice.reducer
  