import {
  drawerWidth,
  transition,
  container,
  drawerWidthSmall,
} from '../../material-dashboard-react.js';

const appStyle = (theme) => ({
  wrapper: {
    position: 'relative',
    top: '0',
    height: '100vh',
    backgroundColor: '#f0f2f5',
  },
  mainPanel: {
    overflow: 'auto',
    position: 'relative',
    float: 'right',
    ...transition,
    maxHeight: '100%',
    width: `calc(100% - ${drawerWidth}px)`,
    overflowScrolling: 'touch',
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${drawerWidthSmall}px)`,
    },
  },
  mainPanelCollapsed: {
    width: `calc(100% - ${drawerWidthSmall}px)`,
  },
  mainPanelOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(20,20,20,0.3)',
    zIndex: 10,
    display: 'none',
  },
  content: {
    marginTop: '70px',
    padding: '20px 15px', // formerly "30px 15px"
    minHeight: 'calc(100vh - 123px)',
    fontWeight: 'normal',
  },
  container,
  map: {
    marginTop: '70px',
  },
});

export default appStyle;
