import { Card, Row, Col, Spin } from 'antd';

export default function Loading({ text, width = '100%', height = 200 }) {
  return (
    <Card>
      <Row
        justify="center"
        align="middle"
        style={{ width, height, textAlign: "center" }}
      >
        <Col>
          <Spin size="large" />
          {text && <p>{text}</p>}
        </Col>
      </Row>
    </Card>
  );
}
