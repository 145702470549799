// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

// core components
import styles from '../../assets/jss/material-dashboard-react/components/sidebarStyle.js';

import SidebarHeader from './SidebarHeader.js';
import SidebarBackground from './SidebarBackground.js';
import SidebarItemsFromRoutes from './SidebarItemsFromRoutes.js';

const useStyles = makeStyles(styles);

const SideBar = (props) => {
  const classes = useStyles();
  const { sidebarCollapsed } = props;

  return (
    <div className='sidebar-style'>
      <div
        className={` menu-drawer  + ${classNames(classes.drawerPaper, {
          [classes.drawerPaperCollapsed + ` menu-drawer-collapsed ` ]: sidebarCollapsed,
        })}`}
      >
        <SidebarHeader classes={classes} sidebarCollapsed={sidebarCollapsed} />
        <SidebarItemsFromRoutes
          classes={classes}
          sidebarCollapsed={sidebarCollapsed}
        />
        <SidebarBackground styleClasses={classes} />
      </div>
    </div>
  );
};

export default SideBar;
