import React from 'react';
import Admin from './UI/layouts/Admin';

const TasksPage = React.lazy(() => import('./UI/views/Tasks/Tasks.js'));
const ExecutionsPage = React.lazy(() =>
  import('./UI/views/Executions/Executions.js'),
);
const ExecutionDetailsPage = React.lazy(() =>
  import('./UI/views/Executions/ExecutionDetails'),
);
const SequencesPage = React.lazy(() =>
  import('./UI/views/Sequences/Sequences.js'),
);
const ConnectionsPage = React.lazy(() =>
  import('./UI/views/Connections/ConnectionsPage.js'),
);
const EnginesPage = React.lazy(() =>
  import('./UI/views/Engines/EnginesPage.js'),
);
const SharedConfigPage = React.lazy(() =>
  import('./UI/views/SharedConfig/SharedConfig.js'),
);
const SharedFieldPage = React.lazy(() =>
  import('./UI/views/SharedField/SharedField.js'),
);
const StatusPage = React.lazy(() => import('./UI/views/Status/StatusPage.js'));
const AppManagementPage = React.lazy(() =>
  import('./UI/views/AppManagement/AppManagementPage.js'),
);
const PermissionPage = React.lazy(() =>
  import('./UI/views/Permissions/PermissionPage.js'),
);
const Dashboards = React.lazy(() =>
  import('./UI/views/Dashboards/Dashboards.js'),
);
const Version = React.lazy(() => import('./UI/views/Version/Version.js'));

const HistoryPage = React.lazy(() =>
  import('./UI/views/History/HistoryPage.js'),
);

const LoginView = React.lazy(() => import('./UI/views/Login/LoginView.js'));
const SetupView = React.lazy(() => import('./UI/views/Setup/SetupView.js'));

const routes = {
  '/:projectID/tasks': (projectID) => (
    <Admin TargetPage={TasksPage} projectID={projectID.projectID} />
  ),
  '/:projectID/tasks/create': (projectID) => (
    <Admin
      TargetPage={TasksPage}
      create={true}
      projectID={projectID.projectID}
    />
  ),
  '/:projectID/tasks/create/:engineID': ({ projectID, engineID }) => (
    <Admin
      TargetPage={TasksPage}
      create={true}
      engineID={engineID}
      projectID={projectID}
    />
  ),
  '/:projectID/tasks/:taskID': ({ projectID, taskID }) => (
    <Admin
      TargetPage={TasksPage}
      edit={true}
      taskID={taskID}
      projectID={projectID}
    />
  ),
  '/:projectID/tasks/dependencies/:taskID': ({ projectID, taskID }) => (
    <Admin
      TargetPage={TasksPage}
      dependencies={true}
      taskID={taskID}
      projectID={projectID}
    />
  ),
  '/:projectID/tasks/:taskID/history': ({ projectID, taskID }) => (
    <Admin
      TargetPage={HistoryPage}
      type={'task'}
      id={taskID}
      projectID={projectID}
    />
  ),

  '/:projectID/executions': ({ projectID }) => (
    <Admin TargetPage={ExecutionsPage} projectID={projectID} />
  ),
  '/:projectID/executions/tasks/:taskID': ({ projectID, taskID }) => (
    <Admin TargetPage={ExecutionsPage} projectID={projectID} taskID={taskID} />
  ),
  '/:projectID/executions/:executionID': ({ projectID, executionID }) => (
    <Admin
      TargetPage={ExecutionDetailsPage}
      projectID={projectID}
      executionID={executionID}
    />
  ),

  '/:projectID/sequences': ({ projectID }) => (
    <Admin TargetPage={SequencesPage} projectID={projectID} />
  ),
  '/:projectID/sequences/create': ({ projectID }) => (
    <Admin TargetPage={SequencesPage} create={true} projectID={projectID} />
  ),
  '/:projectID/sequences/edit/:sequenceID': ({ projectID, sequenceID }) => (
    <Admin
      TargetPage={SequencesPage}
      edit={true}
      projectID={projectID}
      sequenceID={sequenceID}
    />
  ),
  '/:projectID/sequences/executions/:sequenceID': ({
    projectID,
    sequenceID,
  }) => (
    <Admin
      TargetPage={SequencesPage}
      executions={true}
      projectID={projectID}
      sequenceID={sequenceID}
    />
  ),

  '/:projectID/connections': (projectID) => (
    <Admin TargetPage={ConnectionsPage} projectID={projectID.projectID} />
  ),
  '/:projectID/connections/': (projectID) => (
    <Admin TargetPage={ConnectionsPage} projectID={projectID.projectID} />
  ),
  '/:projectID/connections/create': (projectID) => (
    <Admin
      TargetPage={ConnectionsPage}
      create={true}
      projectID={projectID.projectID}
    />
  ),
  '/:projectID/connections/create/:connectionTypeID': ({
    projectID,
    connectionTypeID,
  }) => (
    <Admin
      TargetPage={ConnectionsPage}
      createID={true}
      connectionTypeID={connectionTypeID}
      projectID={projectID}
    />
  ),
  '/:projectID/connections/:connectionID': ({ projectID, connectionID }) => (
    <Admin
      TargetPage={ConnectionsPage}
      edit={true}
      connectionID={connectionID}
      projectID={projectID}
    />
  ),
  '/:projectID/connections/:connectionID/history': ({
    projectID,
    connectionID,
  }) => (
    <Admin
      TargetPage={HistoryPage}
      type={'connection'}
      id={connectionID}
      projectID={projectID}
    />
  ),

  '/:projectID/engines': ({ projectID }) => (
    <Admin TargetPage={EnginesPage} projectID={projectID} />
  ),

  '/:projectID/sharedconfig': ({ projectID }) => (
    <Admin TargetPage={SharedConfigPage} projectID={projectID} />
  ),
  '/:projectID/sharedconfig/create': ({ projectID }) => (
    <Admin TargetPage={SharedConfigPage} create={true} projectID={projectID} />
  ),
  '/:projectID/sharedconfig/create/:sharedConfigUUID': ({
    projectID,
    sharedConfigUUID,
  }) => (
    <Admin
      TargetPage={SharedConfigPage}
      create={true}
      uuid={sharedConfigUUID}
      projectID={projectID}
    />
  ),
  '/:projectID/sharedconfig/:sharedConfigID': ({
    projectID,
    sharedConfigID,
  }) => (
    <Admin
      TargetPage={SharedConfigPage}
      edit={true}
      sharedConfigID={sharedConfigID}
      projectID={projectID}
    />
  ),
  '/:projectID/sharedconfig/:sharedConfigID/history': ({
    projectID,
    sharedConfigID,
  }) => (
    <Admin
      TargetPage={HistoryPage}
      type={'shared-config'}
      id={sharedConfigID}
      projectID={projectID}
    />
  ),

  '/:projectID/sharedfield': ({ projectID }) => (
    <Admin TargetPage={SharedFieldPage} projectID={projectID} />
  ),
  '/:projectID/sharedfield/create': ({ projectID }) => (
    <Admin TargetPage={SharedFieldPage} create={true} projectID={projectID} />
  ),
  '/:projectID/sharedfield/create/:type': ({ projectID, type }) => (
    <Admin
      TargetPage={SharedFieldPage}
      create={true}
      type={type}
      projectID={projectID}
    />
  ),
  '/:projectID/sharedField/create/:type': ({ projectID, type }) => (
    <Admin
      TargetPage={SharedFieldPage}
      create={true}
      type={type}
      projectID={projectID}
    />
  ),
  '/:projectID/sharedfield/:sharedFieldID': ({ projectID, sharedFieldID }) => (
    <Admin
      TargetPage={SharedFieldPage}
      edit={true}
      sharedFieldID={sharedFieldID}
      projectID={projectID}
    />
  ),

  '/:projectID/admin/status': ({ projectID }) => (
    <Admin TargetPage={StatusPage} projectID={projectID} />
  ),
  '/:projectID/admin/appmanagement': ({ projectID }) => (
    <Admin TargetPage={AppManagementPage} projectID={projectID} />
  ),
  '/:projectID/admin/permissions': ({ projectID }) => (
    <Admin TargetPage={PermissionPage} projectID={projectID} />
  ),

  '/:projectID/version': ({ projectID }) => (
    <Admin TargetPage={Version} projectID={projectID} />
  ),

  '/setup': () => <SetupView />,
  '/login': () => <LoginView />,
};

export default routes;
