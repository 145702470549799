import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import Cookies from 'js-cookie';

import { api } from '../../api/api';
import store from '../../../store.js';

export const authenticate = createAsyncThunk(
  'login/authenticate',
  async (_, thunkApi) => {
    const login = store.getState().login;
    try {
      const response = await api.auth(login.username, login.password);
      return response;
    } catch (error) {
      const defaultError = {
        code: 500,
        message: 'An error occured. Please try again.',
      };
      return thunkApi.rejectWithValue({
        error: error.response?.data || defaultError,
      });
    }
  },
);

export const initialState = {
  token: '',
  username: '',
  password: '',
  forgotPassword: false,
  authenticated: false,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    startUp(state, action) {
      // Bootstraps authentication status
      const cookie = Cookies.get('token');
      if (typeof cookie === 'undefined' || cookie === '') {
      } else {
        state.authenticated = true;
      }
    },
    setUsername(state, action) {
      state.username = action.payload;
    },
    setPassword(state, action) {
      state.password = action.payload;
    },
    toggleForgotPassword(state, action) {
      state.forgotPassword = !state.forgotPassword;
    },
    logout(state, action) {
      Cookies.set('token', '');
      state.authenticated = false;
    },
  },
  extraReducers: {
    [authenticate.fulfilled]: (state, action) => {
      state.username = '';
      state.password = '';
      Cookies.set('token', action.payload.token);
      state.authenticated = true;
    },
    [authenticate.rejected]: (state, action) => {},
  },
});

export const {
  setUsername,
  setPassword,
  logout,
  toggleForgotPassword,
  startUp,
} = loginSlice.actions;

export default loginSlice.reducer;
