import {
  createSlice,
  createAsyncThunk,
	createDraftSafeSelector,
} from '@reduxjs/toolkit'

import { api } from '../../api/api'

export const loadEngines = createAsyncThunk('engines/loadEngines', async () => {
  const response = await api.engines()
  return response
})

// selectors
const selectSelf = (state) => state.engines;
export const selectEngines = createDraftSafeSelector(selectSelf, (engines) => engines.engines);

const engineSlice = createSlice({
	name: 'engines',
	initialState: {failedToLoad: false, engines: undefined, allEngines: undefined},
	reducers: {},
	extraReducers: {
		    [loadEngines.fulfilled]: (state, action) => {
				let enabledEngines = []
				action.payload.forEach(engine => {
					if(!engine.disabled) {
						enabledEngines.push(engine)
					}
				})
				enabledEngines = enabledEngines.sort(sortAlphaNum)
		    	state.engines = enabledEngines
				state.allEngines = action.payload
		    },
		    [loadEngines.rejected]: (state, action) => {
		        state.failedToLoad = true
		    },
	},
})

export const {} = engineSlice.actions

export default engineSlice.reducer

// alphanum sorter
function sortAlphaNum(c, d) {
  let a = c.models[0].alias.toLowerCase()
  let b = d.models[0].alias.toLowerCase()
  return a.localeCompare(b, 'en')
}