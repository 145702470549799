import React from 'react';

const CreateTask = React.lazy(() => import('./CreateTask/CreateTask'));
const EditTask = React.lazy(() => import('./EditTask/EditTask'));
const SelectTaskEngine = React.lazy(() => import('./SelectTaskEngine'));
const TaskDependencies = React.lazy(() => import('./TaskDependencies'));
const TaskList = React.lazy(() => import('./TaskList/TaskList'));

export default function TasksPage(props) {
  let Target = <div />;
  if (props.props.create) {
    Target = <SelectTaskEngine />;
    if (props.props.engineID) {
      Target = <CreateTask engineID={props.props.engineID} />;
    }
  } else if (props.props.edit) {
    Target = <EditTask taskID={props.props.taskID} />;
  } else if (props.props.dependencies) {
    Target = <TaskDependencies taskID={props.props.taskID} />;
  } else {
    Target = <TaskList />;
  }

  return <div>{Target}</div>;
}
