import React, { useEffect }  from 'react';
import AceEditor from 'react-ace';

import "ace-builds/src-min-noconflict/theme-cobalt";
import "ace-builds/src-min-noconflict/ext-language_tools";

const loaded = {};

export default function CodeElement(props) {
  const { mode, value, onChange, setOptions, ...restProps } = props;
  
  useEffect(() => {
    let myBoxlist = document.getElementsByClassName("code-box");
    for (let i = 0; i < myBoxlist.length; i++) {
      myBoxlist[i].classList.add("code-box-"+i.toString());
    }
  
    let myBtnlist = document.getElementsByClassName("btn-toggle-fullscreen");
    for (let i = 0; i < myBtnlist.length; i++) {
      myBtnlist[i].classList.add("btn-"+i.toString());
      document.querySelector(".btn-"+i.toString()).onclick = function() {openFullscreen(i)};
    }

    function openFullscreen(index) {
      let elem = document.querySelector(".code-box-"+index.toString())
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { 
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { 
        elem.msRequestFullscreen();
      }
    }

  }, []);

  if (mode && !loaded[mode]) {
    try {
      require('ace-builds/src-min-noconflict/mode-' + mode);
    } catch (err) {
      console.error(error);
    }
  }

  return (
    <>
      <div className="btn-toggle-fullscreen" id="toggle-fullscreen">View full screen</div>
      <AceEditor
          className='code-box'
          mode={mode || 'text'}
          theme="cobalt"
          onChange={onChange}
          name="text-editor"
          value={value}
          editorProps={{ $blockScrolling: true }}
          fontSize={16}
          showPrintMargin={false}
          showGutter={true}
          highlightActiveLine={true}
          setOptions={{
            useWorker: false,
            ...setOptions,
          }}
          {...restProps}
        />
    </>
  );
}
