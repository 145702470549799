/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Assignment from '@material-ui/icons/Assignment';
import FlashOn from '@material-ui/icons/FlashOn';
import AccountTree from '@material-ui/icons/AccountTree';
import UsbIcon from '@material-ui/icons/Usb';
import Apps from '@material-ui/icons/Apps';
import Build from '@material-ui/icons/Build';
import CreateIcon from '@material-ui/icons/Create';
import Speed from '@material-ui/icons/Speed';
import Settings from '@material-ui/icons/Settings';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import BarChart from '@material-ui/icons/BarChart';

// core components/views for Admin layout
import TasksPage from './views/Tasks/Tasks.js';
import UserProfile from './views/UserProfile/UserProfile.js';
import TableList from './views/TableList/TableList.js';
import Typography from './views/Typography/Typography.js';
import EnginesPage from './views/Engines/EnginesPage.js';
import NotificationsPage from './views/Notifications/Notifications.js';

const dashboardRoutes = [
  {
    path: '/tasks',
    name: 'Tasks',
    project: true,
    icon: Assignment,
    component: TasksPage,
    layout: '/admin',
  },
  {
    path: '/executions',
    name: 'Executions',
    project: true,
    icon: FlashOn,
    component: UserProfile,
    layout: '/admin',
  },
  {
    path: '/sequences',
    name: 'Sequences',
    project: true,
    icon: AccountTree,
    component: TableList,
    layout: '/admin',
  },
  {
    path: '/connections',
    name: 'Connections',
    project: true,
    icon: UsbIcon,
    component: Typography,
    layout: '/admin',
  },
  {
    path: '/engines',
    name: 'Apps',
    icon: Apps,
    component: EnginesPage,
    layout: '/admin',
  },
  {
    path: '/sharedconfig',
    name: 'Shared Config',
    project: true,
    icon: Build,
    component: NotificationsPage,
    layout: '/admin',
  },
  {
    path: '/sharedfield',
    name: 'Shared Fields',
    project: true,
    icon: CreateIcon,
    component: NotificationsPage,
    layout: '/admin',
  },
  {
    path: '/admin/status',
    name: 'Status',
    icon: Speed,
    component: NotificationsPage,
    layout: '/admin',
  },
  {
    path: '/admin/appmanagement',
    name: 'App Management',
    icon: Settings,
    component: NotificationsPage,
    layout: '/admin',
  },
  {
    path: '/admin/permissions',
    name: 'Permissions',
    icon: AccountCircleIcon,
    component: NotificationsPage,
    layout: '/admin',
  },
];

export default dashboardRoutes;
