/* If login token fails to connect to api, log out and force re-login */

import { useSelector } from 'react-redux'

import store from '../store.js'
import { logout } from './views/Login/loginSlice'

export default function loginValidator() {
	const state = useSelector((state) => state)
	if (state.engines.failedToLoad) {
		store.dispatch(logout())
	}
}