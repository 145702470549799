import React from 'react';
import { Input, TimePicker, DatePicker, Space, Switch, InputNumber } from 'antd';
import moment from 'moment';
import TimezonePicker from '../../components/TimezonePicker';

import CodeElement from '../../components/Codeelement';

const { Password } = Input;

const INPUT_SIZE = 'middle';
const n00p = () => {};

const inputStyle = {
  width: '100%',
};

const FieldFactory = {
  elements: {},
  meta: {},
  register(type, creator, meta) {
    if (Array.isArray(type)) {
      // register the creator func for all types
      type.forEach((t) => FieldFactory.register(t, creator, meta));
      return;
    }
    FieldFactory.elements[type] = creator;
    FieldFactory.meta[type] = meta || {};
  },
  create(type, props = {}) {
    if (!(type in FieldFactory.elements)) return null;
    return FieldFactory.elements[type](props, type);
  },
  hasMetaProperty(type, prop) {
    if (!(type in FieldFactory.meta)) return false;
    return prop in FieldFactory.meta[type];
  },

  getDefaultValue(type) {
    if (!FieldFactory.hasMetaProperty(type, 'default')) {
      return null;
    }
    return FieldFactory.meta[type]['default'];
  },
};

FieldFactory.register(['password'], (props) => {
  const { options, onChange, onOptionsChange, ...rest } = props;
  const grabValue = (func) => (e) => func(e.target.value);

  return (
    <Password
      size={INPUT_SIZE}
      onChange={grabValue(onChange || n00p)}
      style={inputStyle}
      {...rest}
    />
  );
});

FieldFactory.register(['string', 'int'], (props, type) => {
  const { options, onChange, onOptionsChange, ...rest } = props;
  const grabInputValue = (func) => (e) => func(e.target.value);
  const grabInputNumberValue = (func) => (e) => func(e);

  let grabValue = grabInputValue;
  if (type === 'int') {
    type = 'number';
    grabValue = grabInputNumberValue;
  }

  const Component = type === 'number' ? InputNumber : Input;

  return (
    <Component
      type={type}
      size={INPUT_SIZE}
      onChange={grabValue(onChange || n00p)}
      style={inputStyle}
      {...rest}
    />
  );
}, { default: "" });

FieldFactory.register(
  ['sql', 'json', 'text', 'code'],
  (props, type) => {
    const { value, dialect, disabled, ...rest } = props;

    let mode = dialect;
    if (!mode) {
      if (type === 'sql') mode = 'mysql';
      else if (type === 'json') mode = 'json';
      else if (type === 'code') mode = 'javascript';
    }

    const extra = disabled ? { readOnly: true } : {};

    return (
      <CodeElement
        mode={mode}
        value={value}
        style={inputStyle}
        {...rest}
        {...extra}
      />
    );
  },
  { codeElement: true, default: "" },
);

const timezoneNames = moment.tz.names();
const getTimezoneName = (offset) => {
  return timezoneNames.find((timezoneName) => {
    return offset === moment.tz(timezoneName).format('Z');
  });
};

FieldFactory.register('time', (props) => {
  const { onChange, onOptionsChange, value, options, ...rest } = props;

  let time;
  if (value) time = moment(value, 'HH:mm:ss');
  else time = moment(new Date()).hours(0).minutes(0).seconds(0).utc();

  const update = (e) => (onChange ? onChange(e) : undefined);

  const handleTime = (time) => {
    const value = moment()
      .utc()
      .hours(time.hours())
      .minutes(time.minutes())
      .seconds(time.seconds());

    update(value.format('HH:mm:ss'));
  };

  const handleTimezone = (timezone) => {
    onOptionsChange({ timezone });
  };

  return (
    <Space direction="vertical" size="small" style={{ width: '100%' }}>
      <TimezonePicker
        value={options.timezone}
        onChange={handleTimezone}
        style={{ width: 200, maxWidth: '100%' }}
        {...rest}
      />
      <TimePicker
        value={time}
        size={INPUT_SIZE}
        onChange={handleTime}
        style={inputStyle}
        {...rest}
      />
    </Space>
  );
});

FieldFactory.register('date', (props) => {
  const { options, onOptionsChange, value, onChange, ...rest } = props;
  const update = (date) => (onChange ? onChange(date.format()) : undefined);
  let v = value ? moment(value) : null;

  return (
    <DatePicker
      size={INPUT_SIZE}
      style={inputStyle}
      value={v}
      onChange={update}
      {...rest}
    />
  );
});

FieldFactory.register('datetime', (props) => {
  const { onChange, onOptionsChange, value, options, ...rest } = props;

  let datetime;
  if (value) datetime = moment(value).utc();
  else datetime = moment(new Date()).hours(0).minutes(0).seconds(0).utc();

  const update = (e) => (onChange ? onChange(e.format()) : undefined);

  const handleTime = (time) => {
    const value = datetime
      .hours(time.hours())
      .minutes(time.minutes())
      .seconds(time.seconds());

    update(value);
  };

  const handleTimezone = (timezone) => {
    onOptionsChange({ timezone });
  };

  const handleDate = (date) => {
    const value = date
      .utc()
      .hours(datetime.hours())
      .minutes(datetime.minutes())
      .seconds(datetime.seconds());

    update(value);
  };

  return (
    <Space direction="vertical" size="small" style={{ width: '100%' }}>
      <TimezonePicker
        value={options.timezone}
        onChange={handleTimezone}
        style={{ width: 200, maxWidth: '100%' }}
        {...rest}
      />
      <TimePicker
        value={datetime}
        size={INPUT_SIZE}
        onChange={handleTime}
        style={inputStyle}
        {...rest}
      />
      <DatePicker
        value={datetime}
        size={INPUT_SIZE}
        onChange={handleDate}
        style={inputStyle}
        {...rest}
      />
    </Space>
  );
});

FieldFactory.register('boolean', (props) => {
  const { onChange, onOptionsChange, value, options, ...rest } = props;

  return (
    <Switch
      checked={!!value}
      size={INPUT_SIZE}
      onChange={onChange || n00p}
      {...rest}
    />
  );
}, { default: false });

export default FieldFactory;
